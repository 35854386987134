import axios from "axios";
import config from "../config";

export function getCallRecordings(searchParams) {
  return axios.get(`${config.api_url}/call-recordings`, {
    params: searchParams,
  });
}

export function getCallRecordingTranscriptions(id = "", params) {
  return axios.get(`${config.api_url}/call-recording-transcriptions/${id}`, {
    params: params,
  });
}

export function getCallRecordingsSearchOptions(searchParams) {
  return axios.get(`${config.api_url}/call-recordings/search-options`, {
    params: searchParams,
  });
}

export function transcribeCallRecording(data) {
  return axios.post(`${config.api_url}/call-recordings/transcribe`, data);
}

export function summariseCallRecording(data) {
  return axios.post(`${config.api_url}/call-recordings/summarise`, data);
}

export function summariseExistingTranscription(id) {
  return axios.post(
    `${config.api_url}/call-recording-transcriptions/${id}/summarise`,
  );
}

export function getLeaderboard() {
  return axios.get(
    `${config.api_url}/call-recording-transcriptions/leaderboard`,
  );
}
