import { style } from "../style";

export const useUserLicenceQuotaMessage = (licenceData) => {
  const percentageUsed = (licenceData?.used / licenceData?.limit) * 100;

  if (percentageUsed >= 100) {
    return {
      message:
        "You’ve allocated all your available licences. To create more users, you’ll either need to add more licences to your account or remove licences from existing users.",
      color: "red",
    };
  } else if (percentageUsed >= 80) {
    return {
      message: `You’ve used ${licenceData?.used} of your ${licenceData?.limit} available licences.`,
      color: "yellow",
    };
  } else {
    return {
      message: `You’ve used ${licenceData?.used} of your ${licenceData?.limit} available licences.`,
      style: style.gradientButton,
    };
  }
};
