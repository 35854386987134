import { useNavigate, useParams } from "react-router-dom";
import { Header } from "semantic-ui-react";
import { useUpdateUser } from "../../../hooks/useUpdateUser";
import { useUserDetails } from "../../../hooks/useUserDetails";
import PageLoader from "../../PageLoader";
import UserForm from "./UserForm";

const EditUser = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const {
    userDetails: userData,
    setUserDetails,
    isLoading,
  } = useUserDetails(userId);

  const { updateUserMutation, formErrors } = useUpdateUser(() =>
    navigate("/admin/users"),
  );

  const handleCancel = () => {
    navigate("/admin/users");
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <Header as="h2">Edit User</Header>
      <UserForm
        formErrors={formErrors}
        userDetails={userData}
        setUserDetails={setUserDetails}
        handleSubmit={() => updateUserMutation.mutate({ userId, userData })}
        handleCancel={handleCancel}
        isLoading={isLoading}
        editMode={true}
      />
    </>
  );
};

export default EditUser;
