import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Header,
  Message,
  Pagination,
} from "semantic-ui-react";
import { getUsers } from "../../../api/users";
import { useErrorNotification } from "../../../hooks/useErrorNotification";
import { useUserLicenceQuotaMessage } from "../../../hooks/useUserLicenceQuotaMessage";
import { style } from "../../../style";
import PageLoader from "../../PageLoader";
import UsersTable from "./UsersTable";

const ListUsers = () => {
  const [page, setPage] = useState("1");

  const {
    data: usersData,
    isError,
    isLoading,
  } = useQuery(
    ["users", page],
    () => {
      return getUsers({ page: page });
    },
    {
      keepPreviousData: true,
      staleTime: 60000,
    },
  );

  const licenceQuotaMessage = useUserLicenceQuotaMessage(
    usersData?.data?.meta?.licenses?.sidekick,
  );

  const handlePaginationChange = (e, { activePage }) => {
    setPage(activePage);
  };

  useErrorNotification({
    isError,
    title: "Error getting users",
    toastId: "user-list-error",
  });

  if (isLoading) return <PageLoader />;

  return (
    <>
      <Header as="h2">Manage Users</Header>
      <Message
        color={licenceQuotaMessage.color ? licenceQuotaMessage.color : null}
        style={licenceQuotaMessage.style ? licenceQuotaMessage.style : null}
      >
        {licenceQuotaMessage.message}
      </Message>
      <Link to="/admin/users/create">
        <Button style={{ ...style.gradientButton, width: "250px" }}>
          Add User
        </Button>
      </Link>

      <UsersTable data={usersData?.data?.data} />

      <Container style={{ textAlign: "center" }}>
        <Pagination
          inverted
          activePage={page}
          totalPages={usersData?.data.meta.last_page}
          onPageChange={handlePaginationChange}
        />
      </Container>
    </>
  );
};

export default ListUsers;
