import axios from "axios";
import config from "../config";

export function csrfCookie() {
  return axios.get(`${config.api_url}/sanctum/csrf-cookie`);
}

export function getMe() {
  return axios.get(`${config.api_url}/me`);
}

export function logout() {
  return axios.post(
    `${config.api_url}/logout`,
    {},
    { headers: { Accept: "application/json" } },
  );
}
