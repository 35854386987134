import { useState } from "react";
import { Link } from "react-router-dom";
import { Icon, Menu, MenuItem, Popup } from "semantic-ui-react";
import { logout } from "../api/auth";
import { useAuth } from "../contexts/AuthContext";
import layout from "../layout";
import logo from "../logos/sidekick.svg";

const style = {
  logoArea: {
    width: layout.sidebarWidth,
    marginRight: "10px",
    textAlign: "center",
  },
  logo: {
    height: "24px",
  },
  topbar: {
    paddingRight: "10px",
    height: layout.topbarHeight,
    margin: 0,
    alignItems: "center",
  },
  items: { justifyContent: "flex-end", flexGrow: "1" },
  rightItems: { display: "flex", gap: "12px" },
};

function Topbar({ children }) {
  return (
    <div className="ui inverted menu" style={style.topbar}>
      <div style={style.logoArea}>
        <Link to="/">
          <img style={style.logo} src={logo} alt="Logo" />
        </Link>
      </div>
      <div style={style.items}>{children}</div>

      <div style={style.rightItems}>
        <OptionsMenu />
      </div>
    </div>
  );
}

function OptionsMenu() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popup
      style={{ backgroundColor: "transparent", border: "none" }}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      trigger={
        <div style={{ cursor: "pointer" }}>
          <Icon name="chevron down" />
        </div>
      }
      content={
        <Menu vertical inverted>
          <Logout />
        </Menu>
      }
      on="click"
      position="bottom left"
    />
  );
}

function Logout() {
  const { setApiUser, setLoggingOut } = useAuth();

  const handleLogout = async () => {
    setLoggingOut(true);
    await logout();
    setApiUser();
    setLoggingOut(false);
  };

  return <MenuItem onClick={handleLogout}>Logout</MenuItem>;
}

export default Topbar;
