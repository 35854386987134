import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { getUser } from "../api/users";

export const useUserDetails = (userId) => {
  const { data, isLoading } = useQuery(
    ["user", userId],
    () => getUser(userId),
    {
      keepPreviousData: true,
      staleTime: 60000,
    },
  );

  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    if (data) {
      const {
        first_name,
        last_name,
        email,
        ipecs_ext,
        has_sidekick,
        is_admin,
      } = data?.data?.data;
      setUserDetails({
        first_name: first_name,
        last_name: last_name,
        email: email,
        ipecs_ext: ipecs_ext,
        has_sidekick: has_sidekick,
        admin: is_admin ?? false,
      });
    }
  }, [data]);

  return { userDetails, setUserDetails, isLoading };
};
