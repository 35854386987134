import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";
import { updateUser } from "../api/users";

export const useUpdateUser = (onSuccessNavigate) => {
  const queryClient = useQueryClient();
  const [formErrors, setFormErrors] = useState({});

  const updateUserMutation = useMutation({
    mutationFn: async ({ userId, userData }) => {
      await updateUser(userId, userData);
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: ["users"],
      });
      queryClient.invalidateQueries({
        queryKey: ["user", variables?.userId],
      });
      queryClient.invalidateQueries({
        queryKey: ["team-members-search"],
      });
      toast.success("User updated successfully");
      onSuccessNavigate();
    },
    onError: (error) => {
      if (error?.response?.status === 422) {
        // The error is with the provided input
        setFormErrors(error?.response?.data?.errors);
      }

      if (error?.response?.data?.errors?.license) {
        toast.error(
          "You don't have any more Sidekick licences available to assign.",
        );
      } else {
        toast.error("There was problem updating the user. Please try again.");
      }
    },
  });

  return { updateUserMutation, formErrors };
};
