import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Container,
} from "semantic-ui-react";
import CreateUser from "../components/sidekick/users/CreateUser";

function AddUser() {
  return (
    <Container
      style={{
        margin: "20px 0px",
        display: "flex",
        gap: "25px",
        flexDirection: "column",
      }}
    >
      <Breadcrumb>
        <BreadcrumbSection>Admin</BreadcrumbSection>
        <BreadcrumbDivider />
        <BreadcrumbSection>
          <Link to="/admin/users">Users</Link>
        </BreadcrumbSection>
        <BreadcrumbDivider icon="right angle" />
        <BreadcrumbSection active>Add User</BreadcrumbSection>
      </Breadcrumb>

      <CreateUser />
    </Container>
  );
}

export default AddUser;
