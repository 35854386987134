import { useQuery } from "@tanstack/react-query";
import { Container, DimmerDimmable, Header, Message } from "semantic-ui-react";
import { getLeaderboard } from "../api/transcriptions";
import PageLoader from "../components/PageLoader";
import { LeaderboardTable } from "../components/sidekick/leaderboard/LeaderboardTable";
import { useErrorNotification } from "../hooks/useErrorNotification";
import { style } from "../style";

function Leaderboard() {
  const {
    data: leaderboardData,
    isError,
    isLoading,
  } = useQuery(
    ["leaderboard"],
    () => {
      return getLeaderboard();
    },
    {
      keepPreviousData: true,
      staleTime: 60000,
    },
  );

  useErrorNotification({
    isError,
    title: "Error getting leaderboard data",
    toastId: "leaderboard-error",
    navigateTo: "/",
  });

  if (isLoading) return <PageLoader />;

  const dummyData = [];
  for (let i = 0; i < 10; i++) {
    dummyData.push({
      user: {
        id: i,
        first_name: "John",
        last_name: "Doe",
      },
      score: "80",
    });
  }

  return (
    <Container style={{ margin: "20px 0px" }}>
      <Header as="h2">Leaderboard</Header>
      {leaderboardData?.data?.data?.length === 0 ? (
        <>
          <Message style={style.gradientButton}>
            There aren't enough call summaries yet to generate a leaderboard.
          </Message>
          <DimmerDimmable blurring dimmed={true}>
            <LeaderboardTable data={dummyData} />
          </DimmerDimmable>
        </>
      ) : (
        <>
          <Message style={style.gradientButton}>
            These are the top-10 performing team members since{" "}
            {leaderboardData?.data?.meta?.period_start} based on the sentiment
            of summarised calls.
          </Message>
          <LeaderboardTable data={leaderboardData?.data?.data} />
        </>
      )}
    </Container>
  );
}

export default Leaderboard;
