import { Container, Header, Image } from "semantic-ui-react";
import ManagerLoginDetailsForm from "../components/sidekick/ManagerLoginDetailsForm";
import sidekickLogo from "../images/sidekick-logo-75x79.png";

function ConfigureSidekick({ adminUser }) {
  return (
    <Container>
      <div
        style={{
          maxWidth: "500px",
          margin: "auto",
          marginTop: "50px",
        }}
      >
        <Image src={sidekickLogo} centered />
        {adminUser ? (
          <>
            <Header as="h1" style={{ marginTop: "30px" }}>
              Configure Sidekick
            </Header>
            <p>
              Enter your iPECS manager details to allow Sidekick to transcribe
              and summarise your calls.
            </p>
            <p>
              If you're unsure of your manager details, please speak to your
              phone system administrator.
            </p>
            <ManagerLoginDetailsForm />
          </>
        ) : (
          <>
            <Header as="h1" style={{ marginTop: "30px" }}>
              Sidekick is being set up
            </Header>
            <p>
              Your administrator is currently configuring Sidekick. You'll be
              able to access it once setup is complete.
            </p>
          </>
        )}
      </div>
    </Container>
  );
}

export default ConfigureSidekick;
