import axios from "axios";
import config from "../config";

export function getTeams() {
  return axios.get(`${config.api_url}/teams?type=sidekick`, {});
}

export function getTeam(id = "") {
  return axios.get(`${config.api_url}/teams/${id}?include=members`, {});
}

// Expects an object with: name, type (sidekick), and an array of team members
export function createTeam(data) {
  return axios.post(`${config.api_url}/teams`, data);
}

export function updateTeam(id, data) {
  return axios.put(`${config.api_url}/teams/${id}`, data);
}

export function deleteTeam(id) {
  return axios.delete(`${config.api_url}/teams/${id}`);
}

export function getTeamMembers(id = "", query = "") {
  return axios.get(`${config.api_url}/team-members/${id}?search=${query}`);
}
