import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { updateTeam } from "../api/teams";

export const useUpdateTeam = (teamId, teamName) => {
  const queryClient = useQueryClient();

  const { mutate: updateTeamMutate, isLoading } = useMutation({
    mutationFn: (updatedTeamMembers) => {
      return updateTeam(teamId, {
        name: teamName,
        type: "SIDEKICK",
        members: updatedTeamMembers,
      });
    },
    onSuccess: () => {
      toast.success("Team updated", {
        toastId: "team-updated",
      });
      queryClient.invalidateQueries({
        queryKey: ["team-members", teamId],
      });
      queryClient.invalidateQueries({
        queryKey: ["team-members-search"],
      });
    },
    onError: () => {
      toast.error("There was problem updating the team. Please try again.", {
        toastId: "team-update-error",
      });
    },
  });

  return { updateTeamMutate, isLoading };
};
