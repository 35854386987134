import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export function useErrorNotification({ isError, title, toastId, navigateTo }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (isError) {
      toast.error(title, {
        toastId: toastId,
      });

      if (navigateTo) {
        navigate(navigateTo);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);
}
