import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Confirm,
  Container,
  Icon,
  Popup,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "semantic-ui-react";
import { deleteTeam } from "../../../api/teams";
import { style } from "../../../style";
import PageLoader from "../../PageLoader";

const TeamsTable = (data) => {
  const queryClient = useQueryClient();

  const [confirmationOpen, setConfirmationOpen] = useState({
    open: false,
    id: "",
  });

  const deleteTeamMutation = useMutation({
    mutationFn: (teamData) => {
      return deleteTeam(teamData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["teams"],
      });
    },
    onError: () => {
      toast.error("There was problem deleting the team. Please try again.", {
        toastId: "team-deletion-error",
      });
    },
  });

  const handleDeleteTeam = (id) => {
    setConfirmationOpen({ open: false, id: "" });
    deleteTeamMutation.mutate(id);
  };

  if (deleteTeamMutation.isLoading) return <PageLoader />;

  return (
    <Container style={{ marginTop: "20px" }}>
      {!data?.data || data?.data?.length === 0 ? (
        <>
          <div>No teams found</div>
        </>
      ) : (
        <>
          <Table basic="very" inverted>
            <TableBody>
              {data.data.map((i) => {
                return (
                  <TableRow key={i.id}>
                    <TableCell>{i.name}</TableCell>
                    <TableCell textAlign="right">
                      <Popup
                        position="bottom center"
                        content={"View/edit team"}
                        basic
                        trigger={
                          <Link to={`/admin/teams/${i.id}`}>
                            <Button
                              icon
                              disabled={deleteTeamMutation.isLoading}
                              onClick={() => {}}
                              style={{
                                ...style.purpleGradientButton,
                                marginRight: "10px",
                              }}
                            >
                              <Icon name="group" />
                            </Button>
                          </Link>
                        }
                      />
                      <Popup
                        position="bottom center"
                        content={"Delete team"}
                        basic
                        trigger={
                          <Button
                            icon
                            color="red"
                            disabled={deleteTeamMutation.isLoading}
                            onClick={() =>
                              setConfirmationOpen({ open: true, id: i.id })
                            }
                          >
                            <Icon name="delete" inverted />
                          </Button>
                        }
                      />
                      <Confirm
                        open={
                          confirmationOpen.open && confirmationOpen.id === i.id
                        }
                        header="Delete team"
                        content={`Really delete ${i.name}? You won't be able to undo this.`}
                        confirmButton="Delete"
                        onCancel={() =>
                          setConfirmationOpen({ open: false, id: "" })
                        }
                        onConfirm={() => handleDeleteTeam(i.id)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </>
      )}
    </Container>
  );
};

export default TeamsTable;
