import { toast } from "react-toastify";

export const copyRichText = (element) => {
  navigator.permissions
    .query({ name: "clipboard-write" })
    .then((result) => {
      if (result.state === "granted" || result.state === "prompt") {
        const clipboardItemInput = new ClipboardItem({
          "text/plain": new Blob([element.innerText], {
            type: "text/plain",
          }),
          "text/html": new Blob([element.outerHTML], { type: "text/html" }),
        });

        navigator.clipboard.write([clipboardItemInput]).then(
          () => {
            toast("Copied to clipboard", { type: "success" });
          },
          () => {
            toast("Unable to copy content", { type: "error" });
          },
        );
      } else {
        toast(
          "Unable to copy content. Are you using an up-to-date version of Chrome?",
          { type: "error" },
        );
      }
    })
    .catch(() => {
      toast(
        "It looks like your browser doesn't support automatically copying text. You'll need to select and copy it yourself.",
        { type: "error", autoClose: 1000 * 5 },
      );
    });
};
