import { Container } from "semantic-ui-react";
import ListUsers from "../components/sidekick/users/ListUsers";

function UserAdmin() {
  return (
    <Container
      style={{
        margin: "20px 0px",
        display: "flex",
        gap: "15px",
        flexDirection: "column",
      }}
    >
      <ListUsers />
    </Container>
  );
}

export default UserAdmin;
