import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Header } from "semantic-ui-react";
import { createUser } from "../../../api/users";
import UserForm from "./UserForm";

const CreateUser = () => {
  const [userDetails, setUserDetails] = useState({ admin: false });
  const [formErrors, setFormErrors] = useState({});

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate: createUserMutation, isLoading } = useMutation({
    mutationFn: (data) => {
      return createUser(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["users"],
      });
      toast.success("User added", {
        toastId: "user-added",
      });
      navigate("/admin/users");
    },
    onError: (error) => {
      if (error.response.status === 422) {
        // The error is with the provided input
        setFormErrors(error.response.data.errors);
      }
      toast.error("There was problem adding the user. Please try again.", {
        toastId: "user-creation-error",
      });
    },
  });

  const handleSubmit = async () => {
    createUserMutation(userDetails);
  };

  const handleCancel = () => {
    navigate("/admin/users");
  };

  return (
    <>
      <Header as="h2">Add User</Header>
      <UserForm
        formErrors={formErrors}
        userDetails={userDetails}
        setUserDetails={setUserDetails}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        isLoading={isLoading}
      />
    </>
  );
};

export default CreateUser;
