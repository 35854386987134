import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { Button, Header } from "semantic-ui-react";
import { getTeams } from "../../../api/teams";
import { useErrorNotification } from "../../../hooks/useErrorNotification";
import { style } from "../../../style";
import PageLoader from "../../PageLoader";
import TeamsTable from "./TeamsTable";

const ListTeams = () => {
  const {
    data: teamsData,
    isError,
    isLoading,
  } = useQuery(
    ["teams"],
    () => {
      return getTeams();
    },
    {
      keepPreviousData: true,
      staleTime: 60000,
    },
  );

  useErrorNotification({
    isError,
    title: "Error getting teams list",
    toastId: "team-list-error",
    navigateTo: "/",
  });

  if (isLoading) return <PageLoader />;

  return (
    <>
      <Header as="h2">Manage Teams</Header>
      <Link to="/admin/teams/create">
        <Button style={{ ...style.gradientButton, width: "250px" }}>
          Add Team
        </Button>
      </Link>
      <TeamsTable data={teamsData?.data?.data} />
    </>
  );
};

export default ListTeams;
