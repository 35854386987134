import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createTeam } from "../api/teams";

export const useCreateTeam = (teamName) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState({});

  const { mutate: createTeamMutate, isLoading } = useMutation({
    mutationFn: (teamMembers) => {
      return createTeam({
        name: teamName,
        type: "SIDEKICK",
        members: teamMembers,
      });
    },
    onSuccess: () => {
      toast.success("Team created", {
        toastId: "team-created",
      });

      queryClient.invalidateQueries({
        queryKey: ["teams"],
      });

      queryClient.invalidateQueries({
        queryKey: ["team-members-search"],
      });

      navigate("/admin/teams");
    },
    onError: (error) => {
      if (error?.response?.status === 422) {
        setFormErrors(error?.response?.data?.errors);
      } else {
        toast.error("There was problem creating the team. Please try again.", {
          toastId: "team-create-error",
        });
      }
    },
  });

  return { createTeamMutate, formErrors, isLoading };
};
