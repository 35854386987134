import React, { useContext, useEffect, useState } from "react";
import { csrfCookie, getMe } from "../api/auth";
import SplashScreen from "../components/SplashScreen";

const AuthContext = React.createContext();
const AuthProvider = ({ children }) => {
  const [loadingCsrf, setLoadingCsrf] = useState(true);
  const [loadingMe, setLoadingMe] = useState(true);
  const [apiUser, setApiUser] = useState();
  const [loggingOut, setLoggingOut] = useState(false);

  useEffect(() => {
    csrfCookie().then(() => setLoadingCsrf(false));
  }, []);

  useEffect(() => {
    getMe()
      .then((res) => setApiUser(res.data.data))
      .catch((e) => setApiUser())
      .finally(() => setLoadingMe(false));
  }, []);

  const hasFeature = (features) => {
    return (
      apiUser &&
      apiUser.features.length > 0 &&
      apiUser.features.some((r) => features.includes(r))
    );
  };

  const defaultContext = {
    apiUser,
    setApiUser,
    setLoggingOut,
    hasFeature,
  };
  return (
    <AuthContext.Provider value={defaultContext}>
      {loadingCsrf || loadingMe || loggingOut ? (
        <SplashScreen />
      ) : (
        <>{children}</>
      )}
    </AuthContext.Provider>
  );
};

function useAuth() {
  return useContext(AuthContext);
}

export { AuthProvider, useAuth };
