import {
  Button,
  Checkbox,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";

export const TeamMembersTable = ({
  teamMembers,
  isLoading,
  handleToggleSupervisor,
  handleRemoveUser,
}) => {
  return (
    <Table basic="very" inverted>
      <TableHeader>
        <TableRow>
          <TableHeaderCell width={12}>Name</TableHeaderCell>
          <TableHeaderCell width={2} textAlign="center">
            Supervisor
          </TableHeaderCell>
          <TableHeaderCell width={2} textAlign="center"></TableHeaderCell>
        </TableRow>
      </TableHeader>

      <TableBody>
        {teamMembers?.map((member) => {
          return (
            <TableRow key={member.id}>
              <TableCell>{member.name}</TableCell>
              <TableCell textAlign="center">
                <Checkbox
                  toggle
                  checked={member.supervisor}
                  onChange={() => handleToggleSupervisor(member.id)}
                  disabled={isLoading}
                />
              </TableCell>
              <TableCell textAlign="right">
                <Button
                  icon
                  color="red"
                  onClick={() => handleRemoveUser(member)}
                  disabled={isLoading || teamMembers.length <= 1}
                >
                  <Icon name="user delete" inverted />
                </Button>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
