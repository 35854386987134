import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Checkbox,
  Confirm,
  Container,
  Icon,
  Label,
  Popup,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
import { useUpdateUser } from "../../../hooks/useUpdateUser";
import { style } from "../../../style";

const UsersTable = ({ data }) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [userToToggle, setUserToToggle] = useState({});
  const [licenceStatus, setLicenceStatus] = useState();

  const navigate = useNavigate();

  const { updateUserMutation } = useUpdateUser(() => navigate("/admin/users"));

  const handleRemoveLicence = (user, toggleStatus) => {
    setConfirmationOpen(false);
    const userId = user.id;
    const userData = {
      email: user.email,
      ipecs_ext: user.ipecs_ext,
      has_sidekick: toggleStatus,
      admin: user.is_admin,
    };
    updateUserMutation.mutate({ userId, userData });
  };

  const handleToggleLicence = (user, toggleStatus) => {
    setUserToToggle(user);
    setLicenceStatus(toggleStatus);
    if (user.has_connect) {
      // Connect user: disable licence without prompt
      handleRemoveLicence(user, toggleStatus);
    } else {
      // Sidekick user: prompt to delete user
      setUserToToggle(user);
      setLicenceStatus(toggleStatus);
      setConfirmationOpen(true);
    }
  };

  return (
    <Container style={{ marginTop: "20px" }}>
      {data?.length === 0 ? (
        <p>No users found</p>
      ) : (
        <Table basic="very" inverted>
          <TableHeader>
            <TableRow>
              <TableHeaderCell width={5}>Name</TableHeaderCell>
              <TableHeaderCell width={3}>Extension</TableHeaderCell>
              <TableHeaderCell width={4}>Licence</TableHeaderCell>
              <TableHeaderCell width={2} textAlign="left">
                <span
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  Sidekick user{" "}
                  <Popup
                    content="Disabling a user will allow you to assign the licence to another user. If the user doesn't also have a Connect licence, disabling them will delete their account."
                    basic
                    trigger={<Icon name="info circle" size="small" />}
                  />
                </span>
              </TableHeaderCell>
              <TableHeaderCell width={2}></TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {data.map((user) => {
              return (
                <TableRow key={user.id}>
                  <TableCell>
                    {user.first_name} {user.last_name}
                  </TableCell>
                  <TableCell>{user.ipecs_ext}</TableCell>
                  <TableCell>
                    {user.has_connect ? (
                      <Label color="blue" horizontal>
                        Connect
                      </Label>
                    ) : (
                      <Label color="purple" horizontal>
                        Sidekick
                      </Label>
                    )}
                  </TableCell>
                  <TableCell textAlign="left">
                    <Checkbox
                      toggle
                      checked={user.has_sidekick}
                      disabled={updateUserMutation.isLoading}
                      onChange={(e, data) => {
                        handleToggleLicence(user, data.checked);
                      }}
                    />
                  </TableCell>
                  <TableCell
                    textAlign="right"
                    style={{
                      gap: "25px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Popup
                      position="bottom center"
                      content={"Edit user"}
                      basic
                      trigger={
                        <Link to={`/admin/users/${user.id}`}>
                          <Button
                            icon
                            disabled={updateUserMutation.isLoading}
                            onClick={() => {}}
                            style={style.purpleGradientButton}
                          >
                            <Icon name="edit" />
                          </Button>
                        </Link>
                      }
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
      <DeleteConfirmation
        open={confirmationOpen}
        user={userToToggle}
        licenceStatus={licenceStatus}
        onConfirm={handleRemoveLicence}
        onCancel={() => setConfirmationOpen(false)}
      />
    </Container>
  );
};

const DeleteConfirmation = ({
  open,
  user,
  licenceStatus,
  onConfirm,
  onCancel,
}) => (
  <Confirm
    open={open}
    header="Delete user?"
    content={`${user.first_name} ${user.last_name} only has a Sidekick licence, so deactivating the licence will delete their account.`}
    confirmButton="Delete"
    onCancel={onCancel}
    onConfirm={() => onConfirm(user, licenceStatus)}
  />
);

export default UsersTable;
