import { useQuery } from "@tanstack/react-query";
import { getTeamMembers } from "../api/teams";

export function useAvailableTeamMembers({ teamId, query, addedTeamMembers }) {
  const { data, isFetching } = useQuery(
    ["team-members-search", teamId, query],
    () => getTeamMembers(teamId, query),
    { keepPreviousData: true, staleTime: 60000 },
  );

  const membersInList =
    data?.data?.data?.map((member) => ({
      key: member.id,
      text: member.name,
      value: member.id,
    })) || [];

  const list = membersInList.filter(
    (member) => !addedTeamMembers?.find((m) => m === member.key),
  );

  return { data: list, isLoading: isFetching };
}
