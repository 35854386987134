export const style = {
  gradientButton: {
    color: "white",
    background:
      "transparent linear-gradient(140deg, #98A0D9 0%, #58B9B6 100%) 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    border: "1px solid #D2D3EC",
  },
  gradientButtonInactive: {
    color: "white",
    background: "transparent ",
    boxShadow: "0px 3px 6px #00000029",
    border: "1px solid #D2D3EC",
  },
  purpleGradientButton: {
    color: "white",
    background:
      "transparent linear-gradient(128deg, #98A0D9 0%, #CA98D9 100%) 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    border: "1px solid #D2D3EC",
  },
  modalSummaryButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "8px 21px",
    width: "110px",
    justifyContent: "space-around",
  },
};
