import { Dropdown } from "semantic-ui-react";

const TeamMembersDropdown = ({
  availableTeamMembers,
  isLoading,
  setSearchQuery,
  handleAddTeamMember,
}) => {
  return (
    <>
      <Dropdown
        placeholder="Search users"
        search
        selection
        clearable
        options={availableTeamMembers}
        loading={isLoading}
        onSearchChange={(e, { searchQuery }) => setSearchQuery(searchQuery)}
        onChange={(e, { value }) => {
          const user = availableTeamMembers.find((o) => o.value === value);
          user &&
            handleAddTeamMember({
              id: user.key,
              name: user.text,
              supervisor: false,
            });
        }}
      />
    </>
  );
};

export default TeamMembersDropdown;
