import { Container } from "semantic-ui-react";
import ListTeams from "../components/sidekick/teams/ListTeams";

function TeamAdmin() {
  return (
    <Container
      style={{
        margin: "20px 0px",
        display: "flex",
        gap: "15px",
        flexDirection: "column",
      }}
    >
      <ListTeams />
    </Container>
  );
}

export default TeamAdmin;
