export const teamMembersReducer = (team, action) => {
  switch (action.type) {
    case "SET_TEAM_MEMBERS": {
      return { members: action.data, removed: [], added: [] };
    }
    case "ADD_TEAM_MEMBER": {
      return {
        added: [...team.added, action.user.id],
        removed: team.removed.filter((id) => id !== action.user.id),
        members: [...team.members, action.user],
      };
    }
    case "TOGGLE_SUPERVISOR": {
      return {
        ...team,
        members: team.members.map((member) =>
          member.id === action.id
            ? { ...member, supervisor: !member.supervisor }
            : member,
        ),
      };
    }
    case "REMOVE_TEAM_MEMBER": {
      return {
        added: team.added.filter((id) => id !== action.user.id),
        removed: [...team.removed, action.user.id],
        members: team.members.filter((member) => member.id !== action.user.id),
      };
    }
    case "RESET_DATA": {
      return {
        members: action.data,
        removed: [],
        added: [],
      };
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
};
