import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { getCallRecordingsSearchOptions } from "../api/transcriptions";

export const useSearchOptions = (userSearch) => {
  const { data: searchOptionsData, isFetching } = useQuery(
    ["search-options", userSearch],
    () => getCallRecordingsSearchOptions({ search: userSearch }),
    {
      keepPreviousData: true,
      staleTime: 60000,
    },
  );

  const [searchOptions, setSearchOptions] = useState({});

  useEffect(() => {
    if (searchOptionsData) {
      const users = searchOptionsData.data.data;

      setSearchOptions({
        usersDropdownList: users.map((user) => ({
          key: user.id,
          value: user.ext,
          text: `${user.name}${user.ext ? ` (${user.ext})` : ""}`,
        })),
      });
    }
  }, [searchOptionsData]);

  return [searchOptions, isFetching];
};
