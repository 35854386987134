import {
  Button,
  Checkbox,
  Form,
  FormButton,
  FormField,
  Icon,
  Input,
  Label,
  Popup,
} from "semantic-ui-react";
import { style } from "../../../style";

const UserForm = ({
  formErrors,
  userDetails,
  setUserDetails,
  handleSubmit,
  handleCancel,
  isLoading,
  editMode,
}) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div
      style={{
        maxWidth: "750px",
      }}
    >
      <Form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
        <FormField>
          <label>First name</label>
          {"first_name" in formErrors && (
            <Label color="red">First name is required</Label>
          )}
          <Input
            value={userDetails?.first_name ?? ""}
            disabled={editMode}
            onChange={(e, { value }) =>
              setUserDetails({ ...userDetails, first_name: value })
            }
          />
        </FormField>
        <FormField>
          <label>Last name</label>
          {"last_name" in formErrors && (
            <Label color="red">Last name is required</Label>
          )}
          <Input
            value={userDetails?.last_name ?? ""}
            disabled={editMode}
            onChange={(e, { value }) =>
              setUserDetails({ ...userDetails, last_name: value })
            }
          />
        </FormField>
        <FormField>
          <label>Email address</label>
          {"email" in formErrors && (
            <Label color="red">Email address is required</Label>
          )}
          <Input
            value={userDetails?.email ?? ""}
            disabled={editMode}
            onChange={(e, { value }) => {
              setUserDetails({ ...userDetails, email: value });
            }}
          />
        </FormField>
        <FormField>
          <label>iPECS extension</label>
          {"ipecs_ext" in formErrors && (
            <Label color="red">iPECS extension is required</Label>
          )}
          <Input
            value={userDetails?.ipecs_ext ?? ""}
            onChange={(e, { value }) => {
              setUserDetails({ ...userDetails, ipecs_ext: value });
            }}
          />
        </FormField>
        <FormField
          style={{
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            gap: "5px",
            marginTop: "30px",
          }}
        >
          <Checkbox
            label="Admin user"
            checked={userDetails?.admin ?? false}
            toggle
            onChange={(e, { checked }) => {
              setUserDetails({ ...userDetails, admin: checked });
            }}
          />
          <Popup
            content="Admins can add, edit and delete users and view all call transcriptions and summaries. They can also manage teams and assign and revoke sidekick licences."
            basic
            trigger={<Icon name="info circle" size="small" />}
          />
        </FormField>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            marginTop: "40px",
            gap: "10px",
          }}
        >
          <Button onClick={handleCancel} disabled={isLoading}>
            Cancel
          </Button>
          <FormButton
            type="submit"
            style={style.gradientButton}
            disabled={isLoading}
            loading={isLoading}
          >
            Save
          </FormButton>
        </div>
      </Form>
    </div>
  );
};

export default UserForm;
