import {
  Icon,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
import { getSentimentIcon } from "../../../utils/getSentimentIcon";

export const LeaderboardTable = ({ data }) => {
  return (
    <Table
      basic="very"
      inverted
      style={{ maxWidth: "700px", marginTop: "50px" }}
    >
      <TableHeader>
        <TableRow>
          <TableHeaderCell width={2}>Position</TableHeaderCell>
          <TableHeaderCell width={10}>Name</TableHeaderCell>
          <TableHeaderCell width={4}>Score</TableHeaderCell>
        </TableRow>
      </TableHeader>

      <TableBody>
        {data?.map((e, i) => {
          const sentimentIcon = getSentimentIcon(Math.ceil(e.score / 20));
          return (
            <TableRow key={e.user.id}>
              <TableCell>{i + 1}</TableCell>
              <TableCell>
                {e.user.first_name} {e.user.last_name}
              </TableCell>
              <TableCell>
                {e.score}{" "}
                <Icon
                  name={sentimentIcon.name}
                  size="large"
                  style={{ color: sentimentIcon.color, marginLeft: "10px" }}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
