import { Button, Icon, Popup, TableCell, TableRow } from "semantic-ui-react";
import { style } from "../../style";
import { getSentimentIcon } from "../../utils/getSentimentIcon";

const CallTranscriptionRow = ({
  id,
  status,
  hasSummary,
  summary,
  sentiment,
  direction,
  number,
  extension,
  start,
  end,
  duration,
  setInfoModal,
}) => {
  const sentimentIcon = getSentimentIcon(sentiment?.rating);

  return (
    <TableRow>
      <TableCell>{extension}</TableCell>
      <TableCell>{number}</TableCell>
      <TableCell>{direction}</TableCell>
      <TableCell>{start}</TableCell>
      <TableCell>{end}</TableCell>
      <TableCell>{duration}</TableCell>
      <TableCell>{summary}</TableCell>
      <TableCell textAlign="center">
        {sentiment && sentiment?.rating && (
          <Popup
            wide
            position="bottom center"
            trigger={
              <Icon
                name={sentimentIcon?.name}
                size="big"
                style={{ color: sentimentIcon?.color }}
              />
            }
          >
            {sentiment?.summary}
          </Popup>
        )}
      </TableCell>
      <TableCell>
        <Button
          style={style.gradientButton}
          disabled={status.value !== "PROCESSED"}
          loading={status.value !== "PROCESSED"}
          onClick={() =>
            setInfoModal({
              open: true,
              id: id,
              tab: hasSummary ? "summary" : "transcription",
            })
          }
        >
          View
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default CallTranscriptionRow;
