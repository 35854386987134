import axios from "axios";
import config from "../config";

export function getUsers(params) {
  return axios.get(`${config.api_url}/users?type=sidekick`, {
    params: params,
  });
}

export function getUser(id) {
  return axios.get(`${config.api_url}/users/${id}`, {});
}

export function createUser(data) {
  return axios.post(`${config.api_url}/users`, data);
}

export function updateUser(id, data) {
  return axios.put(`${config.api_url}/users/${id}`, data);
}
