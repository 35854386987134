import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import axios from "axios";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "semantic-ui-less/semantic.less";
import App from "./App";
import config from "./config";
import { AuthProvider } from "./contexts/AuthContext";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createIDBPersister } from "./utils/IDBPersister";

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
axios.defaults.headers.common = {
  "X-API-VERSION": config.api_version,
  "X-APP-URL": config.app_url,
  Accept: "application/json",
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 5000, //5 seconds
      cacheTime: 1000 * 60 * 60 * 24 * 5, // 5 days, this should be higher than maxAge on persistent cache
    },
  },
});

const persister = createIDBPersister("sidekick");
const doNotPersistQueries = ["team-members-search"];

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <PersistQueryClientProvider
    client={queryClient}
    uster={config.api_version}
    persistOptions={{
      persister: persister,
      maxAge: 1000 * 60 * 60 * 24 * 5, // 3 days
      hydrateOptions: {},
      dehydrateOptions: {
        shouldDehydrateQuery: ({ queryKey, state }) => {
          return (
            state.status === "success" &&
            !doNotPersistQueries.includes(queryKey[0] ?? "")
          );
        },
      },
    }}
  >
    <AuthProvider>
      <App />
    </AuthProvider>
    <ToastContainer
      style={{ zIndex: 999999999 }}
      position="top-center"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
    />
  </PersistQueryClientProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
