import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Container,
  FormField,
  Header,
  Input,
  Label,
} from "semantic-ui-react";
import ManageTeam from "../components/sidekick/teams/ManageTeam";

function AddTeam() {
  const [teamName, setTeamName] = useState("");
  const [errors, setErrors] = useState({});

  return (
    <Container>
      <Breadcrumb>
        <BreadcrumbSection>Admin</BreadcrumbSection>
        <BreadcrumbDivider />
        <BreadcrumbSection>
          <Link to="/admin/teams">Teams</Link>
        </BreadcrumbSection>
        <BreadcrumbDivider icon="right angle" />
        <BreadcrumbSection active>Add Team</BreadcrumbSection>
      </Breadcrumb>

      <Header as="h1">Add Team</Header>

      <FormField
        style={{ display: "flex", flexDirection: "column", width: "300px" }}
      >
        {"name" in errors && <Label color="red">{errors.name}</Label>}
        <Input
          placeholder="Team name"
          style={{ width: "300px" }}
          onChange={(e) => setTeamName(e.target.value)}
        />
      </FormField>
      <ManageTeam
        data={{ id: "", name: teamName, members: [] }}
        createTeam
        setTeamNameError={setErrors}
      />
    </Container>
  );
}

export default AddTeam;
