import { Container } from "semantic-ui-react";
import logo from "../logos/sidekick.svg";

const style = {
  container: {
    height: "90vh",
    display: "flex",
    alignItems: "center",
  },
  logo: {
    width: "50vw",
    maxWidth: "220px",
    minWidth: "120px",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "4rem",
  },
};

function SplashScreen() {
  return (
    <Container fluid style={style.container}>
      <div style={style.logo}>
        <img height="180" src={logo} alt="Logo" />
      </div>
    </Container>
  );
}

export default SplashScreen;
