import { useDebounce } from "@uidotdev/usehooks";
import { useReducer, useState } from "react";
import { Button, Container, Header } from "semantic-ui-react";
import { teamMembersReducer } from "../../../hooks/teamMembersReducer";
import { useAvailableTeamMembers } from "../../../hooks/useAvailableTeamMembers";
import { useCreateTeam } from "../../../hooks/useCreateTeam";
import { useUpdateTeam } from "../../../hooks/useUpdateTeam";
import { style } from "../../../style";
import TeamMembersDropdown from "./TeamMembersDropdown";
import { TeamMembersTable } from "./TeamMembersTable";

const ManageTeam = ({ data, createTeam, setTeamNameError }) => {
  const { id: teamId, name: teamName, members: initialTeamMembers } = data;

  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 400);

  const [team, dispatch] = useReducer(teamMembersReducer, {
    members: initialTeamMembers,
    removed: [],
    added: [],
  });

  const isDataChanged =
    JSON.stringify(team.members) !== JSON.stringify(initialTeamMembers);

  const { data: availableTeamMembers, isLoading } = useAvailableTeamMembers({
    teamId: teamId,
    query: debouncedSearchQuery,
    addedTeamMembers: team.added,
  });

  const handleToggleSupervisor = (userId) => {
    dispatch({ type: "TOGGLE_SUPERVISOR", id: userId });
  };

  const handleAddTeamMember = (user) => {
    dispatch({ type: "ADD_TEAM_MEMBER", user: user });
  };

  const handleRemoveTeamMember = (user) => {
    dispatch({ type: "REMOVE_TEAM_MEMBER", user: user });
  };

  const handleResetData = () => {
    dispatch({ type: "RESET_DATA", data: initialTeamMembers });
  };

  const { updateTeamMutate, isLoading: updateMutationIsLoading } =
    useUpdateTeam(teamId, teamName);

  const {
    createTeamMutate,
    formErrors,
    isLoading: createMutationIsLoading,
  } = useCreateTeam(data?.name);

  createTeam && setTeamNameError(formErrors);

  const handleSubmit = async () => {
    if (createTeam) {
      createTeamMutate(team.members);
    } else {
      updateTeamMutate(team.members);
    }
  };

  return (
    <Container style={{ marginTop: "20px" }}>
      {!createTeam && <Header as="h1">{teamName}</Header>}
      {team?.members?.length !== 0 && (
        <TeamMembersTable
          teamMembers={team.members}
          isLoading={isLoading}
          handleToggleSupervisor={handleToggleSupervisor}
          handleRemoveUser={handleRemoveTeamMember}
        />
      )}
      <Container style={{ gap: "10px" }}>
        <Header>Add team members</Header>
        <TeamMembersDropdown
          availableTeamMembers={availableTeamMembers}
          isLoading={isLoading}
          handleAddTeamMember={handleAddTeamMember}
          setSearchQuery={setSearchQuery}
        />
      </Container>
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "end",
          marginTop: "40px",
        }}
      >
        <Button
          disabled={
            !isDataChanged || createMutationIsLoading || updateMutationIsLoading
          }
          onClick={handleResetData}
        >
          Cancel
        </Button>
        <Button
          disabled={
            !isDataChanged || createMutationIsLoading || updateMutationIsLoading
          }
          loading={updateMutationIsLoading || createMutationIsLoading}
          onClick={handleSubmit}
          style={style.gradientButton}
        >
          Save
        </Button>
      </div>
    </Container>
  );
};

export default ManageTeam;
