import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  Container,
  Header,
  Icon,
  Segment,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
import { getCallRecordings } from "../../api/transcriptions";
import { style } from "../../style";
import PageLoader from "../PageLoader";
import CallRecordingRow from "./CallRecordingRow";

const CallRecordings = ({
  setInfoModal,
  children,
  searchParams,
  setIsSearching,
}) => {
  const [sidekickHelpTextHidden, setSidekickHelpTextHidden] = useState(
    localStorage.getItem("sidekickHelpTextHidden") || false,
  );

  const queryClient = useQueryClient();

  const recordingsQuery = useQuery(
    ["call-recordings", searchParams],
    () => {
      return getCallRecordings(searchParams);
    },
    {
      keepPreviousData: true,
      refetchIntervalInBackground: true,
      refetchInterval: (data) => {
        let interval = false;
        data?.data.data.forEach((i) => {
          if (
            ["SUMMARISING", "TRANSCRIBING"].includes(
              i?.transcription?.status.value,
            )
          ) {
            queryClient.invalidateQueries({
              queryKey: ["call-transcriptions"],
            });
            interval = 30 * 1000;
          }
        });
        return interval;
      },
    },
  );

  useEffect(() => {
    setIsSearching(recordingsQuery.isRefetching);
  }, [setIsSearching, recordingsQuery.isRefetching]);

  if (recordingsQuery.isLoading) return <PageLoader>Loading...</PageLoader>;
  if (recordingsQuery.error)
    return <div>Failed to retrieve data from iPECS</div>;

  return (
    <Container style={{ marginTop: "20px" }}>
      {!sidekickHelpTextHidden && (
        <Segment
          inverted
          style={{
            ...style.purpleGradientButton,
            marginBottom: "35px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "12px",
            }}
          >
            <Header size="medium" style={{ width: "80%", marginBottom: "0px" }}>
              Sidekick turbobcharges your workflow by summarising calls,
              generating meeting notes and helping you identify next actions for
              your projects.
            </Header>
            <Icon
              name="x"
              size="large"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSidekickHelpTextHidden(true);
                localStorage.setItem("sidekickHelpTextHidden", true);
              }}
            />
          </div>
          <p>
            <i>
              If you choose "transcribe only" on a call recording below you can
              still use AI on this call at a later date.
            </i>
          </p>
        </Segment>
      )}
      <Table basic="very" inverted>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>Extension</TableHeaderCell>
            <TableHeaderCell>Number</TableHeaderCell>
            <TableHeaderCell>Direction</TableHeaderCell>
            <TableHeaderCell>Start Time</TableHeaderCell>
            <TableHeaderCell>End Time</TableHeaderCell>
            <TableHeaderCell>Duration</TableHeaderCell>
            <TableHeaderCell style={{ width: "140px" }}></TableHeaderCell>
          </TableRow>
        </TableHeader>

        <TableBody>
          {recordingsQuery.data.data.data.length === 0 && (
            <tr>
              <td colSpan={5} style={{ paddingTop: "12px" }}>
                No call recordings found.
              </td>
            </tr>
          )}

          {recordingsQuery.data.data.data.map((i) => {
            return (
              <CallRecordingRow
                id={i.slug}
                key={`recording-row-${i.slug}`}
                number={i.tel_number}
                direction={i.direction}
                start={i.start}
                end={i.end}
                extension={i.ext}
                duration={i.duration}
                transcription={i.transcription}
                setInfoModal={setInfoModal}
                searchParams={searchParams}
              />
            );
          })}
        </TableBody>
      </Table>
      {children}
    </Container>
  );
};

export default CallRecordings;
