import { Container, Segment } from "semantic-ui-react";

const style = {
  container: {
    paddingTop: "6rem",
    padding: "3rem",
    textAlign: "center",
    display: "flex",
    flexDirection: " column",
    justifyContent: "center",
    alignItems: "center",
  },
};

function NoAccess() {
  return (
    <Container>
      <Segment basic style={style.container}>
        <h1>Unauthorised</h1>
        <p>You do not currently have access to Sidekick.</p>
      </Segment>
    </Container>
  );
}

export default NoAccess;
