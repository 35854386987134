import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, FormButton, FormField, Input, Label } from "semantic-ui-react";
import { useUpdateManagerLogin } from "../../hooks/useUpdateManagerLogin";
import { style } from "../../style";

const ManagerLoginDetailsForm = () => {
  const [managerDetails, setManagerDetails] = useState({});

  const navigate = useNavigate();
  const { updateManagerLoginMutate, isLoading, formErrors } =
    useUpdateManagerLogin(() => navigate(0));

  const handleSubmit = () => {
    updateManagerLoginMutate(managerDetails);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <Form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
      <FormField>
        <label>URL</label>
        {"url" in formErrors && <Label color="red">{formErrors?.url}</Label>}
        <Input
          value={managerDetails?.url ?? ""}
          disabled={isLoading}
          onChange={(e, { value }) =>
            setManagerDetails({ ...managerDetails, url: value })
          }
        />
      </FormField>
      <FormField>
        <label>Username</label>
        {"username" in formErrors && (
          <Label color="red">{formErrors?.username}</Label>
        )}
        <Input
          value={managerDetails?.username ?? ""}
          disabled={isLoading}
          onChange={(e, { value }) =>
            setManagerDetails({ ...managerDetails, username: value })
          }
        />
      </FormField>
      <FormField>
        <label>Password</label>
        {"password" in formErrors && (
          <Label color="red">{formErrors?.password}</Label>
        )}
        <Input
          type="password"
          value={managerDetails?.password ?? ""}
          disabled={isLoading}
          onChange={(e, { value }) =>
            setManagerDetails({ ...managerDetails, password: value })
          }
        />
      </FormField>
      <FormButton
        type="submit"
        disabled={isLoading}
        loading={isLoading}
        style={{ ...style.gradientButton, width: "100%" }}
      >
        Save
      </FormButton>
    </Form>
  );
};

export default ManagerLoginDetailsForm;
