import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";
import { updateManagerLogin } from "../api/admin";

export const useUpdateManagerLogin = (onSuccessNavigate) => {
  const [formErrors, setFormErrors] = useState({});

  const { mutate: updateManagerLoginMutate, isLoading } = useMutation({
    mutationFn: async (managerData) => {
      await updateManagerLogin(managerData);
    },
    onSuccess: () => {
      toast.success("Sidekick has been configured");
      setTimeout(() => onSuccessNavigate(), 1500);
    },
    onError: (error) => {
      if (error?.response?.status === 422) {
        // The error is with the provided input
        setFormErrors(error?.response?.data?.errors);
      } else {
        toast.error("There was problem setting up Sidekick. Please try again.");
      }
    },
  });

  return { updateManagerLoginMutate, formErrors, isLoading };
};
