import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { toast } from "react-toastify";
import {
  Button,
  ButtonGroup,
  Container,
  Dimmer,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextArea,
  Header,
  Icon,
  Loader,
  Message,
  Progress,
  Segment,
} from "semantic-ui-react";
import {
  generateFile,
  generateScript,
  getVoicePreview,
  getVoices,
} from "../api/moh";
import { style } from "../style";

function Moh() {
  const [stepNumber, setStepNumber] = useState(0);
  const [company, setCompany] = useState("");
  const [industry, setIndustry] = useState("");
  const [services, setServices] = useState("");
  const [other, setOther] = useState("");
  const [tone, setTone] = useState("friendly");
  const [length, setLength] = useState("short");
  const [script, setScript] = useState("");
  const [voice, setVoice] = useState("");
  const [music, setMusic] = useState("flow");
  const [formErrors, setFormErrors] = useState({});
  const [generatedUrl, setGeneratedUrl] = useState();

  const voicesQuery = useQuery({
    queryKey: ["get-voices"],
    queryFn: getVoices,
  });

  const generateScriptMutation = useMutation({
    mutationFn: () => {
      setScript("");
      return generateScript({
        company: company,
        industry: industry,
        services: services,
        length: length,
        tone: tone,
        other: other,
      });
    },
    onSuccess: (res) => {
      setScript(res.data?.data ?? "");
      setGeneratedUrl();
      setStepNumber((prev) => prev + 1);
    },
    onError: (error) => {
      if (error?.response?.status === 422) {
        setFormErrors(error?.response?.data?.errors);
      } else {
        toast.error(
          "There was an error creating the script. Please try again.",
          {
            toastId: "generate-script-error",
          },
        );
      }
    },
  });

  const generateFileMutation = useMutation({
    mutationFn: () => {
      return generateFile({
        script: script,
        music: music,
        voice: voice,
      });
    },
    onSuccess: (res) => {
      setGeneratedUrl(res.data.data.url);
      setStepNumber((prev) => prev + 1);
    },
    onError: (error) => {
      const message =
        error.response.data.message ??
        "There was an error generating the file. Please try again.";
      toast.error(message, {
        toastId: "generate-file-error",
      });
    },
  });

  const handleChangeField = (name, value, func) => {
    const newErrors = { ...formErrors };
    delete newErrors[name];
    setFormErrors(newErrors);
    func(value);
  };

  const renderStep = (step) => {
    switch (step) {
      case 0:
        return renderScriptOptions();
      case 1:
        return renderScriptAmends();
      case 2:
        return renderAudioOptions();
      case 3:
        return renderDownload();
      default:
        return <div>Error</div>;
    }
  };

  const renderScriptOptions = () => {
    return (
      <div>
        <Message
          color="black"
          icon="headphones"
          header="Generate your message"
          content="Let's get started by generating your message using some basic information."
        />

        <Form onSubmit={() => generateScriptMutation.mutate()}>
          <FormGroup widths="equal">
            <FormInput
              required
              fluid
              name="company"
              label="Company"
              placeholder="What's your company name?"
              error={
                formErrors?.company
                  ? {
                      content: formErrors?.company[0] ?? "",
                      pointing: "above",
                    }
                  : false
              }
              value={company}
              onChange={(_, { name, value }) =>
                handleChangeField(name, value, setCompany)
              }
            />
            <FormInput
              required
              fluid
              name="industry"
              label="Industry"
              placeholder="What industry do you work in?"
              error={
                formErrors?.industry
                  ? {
                      content: formErrors?.industry[0] ?? "",
                      pointing: "above",
                    }
                  : false
              }
              value={industry}
              onChange={(_, { name, value }) =>
                handleChangeField(name, value, setIndustry)
              }
            />
          </FormGroup>

          <FormGroup widths="equal">
            <FormInput
              required
              fluid
              name="services"
              label="Services"
              placeholder="What services do you provide?"
              error={
                formErrors?.services
                  ? {
                      content: formErrors?.services[0] ?? "",
                      pointing: "above",
                    }
                  : false
              }
              value={services}
              onChange={(_, { name, value }) =>
                handleChangeField(name, value, setServices)
              }
            />
          </FormGroup>

          <FormGroup widths="equal">
            <FormSelect
              required
              fluid
              label="Length of script"
              name="length"
              options={[
                { text: "Short", value: "short" },
                { text: "Medium", value: "medium" },
                { text: "Long", value: "long" },
              ]}
              error={formErrors?.length ? true : false}
              placeholder="Length of script"
              value={length}
              onChange={(_, { name, value }) =>
                handleChangeField(name, value, setLength)
              }
            />
            <FormSelect
              required
              fluid
              label="Tone"
              name="tone"
              options={[
                { text: "Friendly", value: "friendly" },
                { text: "Professional", value: "professional" },
                { text: "Upbeat", value: "upbeat" },
                { text: "Calm", value: "calm" },
                { text: "Informative", value: "informative" },
                { text: "Casual", value: "casual" },
                { text: "Lighthearted", value: "lighthearted" },
                { text: "Motivational", value: "motivational" },
              ]}
              error={formErrors?.tone ? true : false}
              placeholder="Tone of message"
              value={tone}
              onChange={(_, { name, value }) =>
                handleChangeField(name, value, setTone)
              }
            />
          </FormGroup>

          <FormTextArea
            label="Anything else?"
            name="other"
            placeholder="Anything else that you would like to add to the script? For example, opening hours, product promotions or the season for this message."
            value={other}
            onChange={(_, { name, value }) =>
              handleChangeField(name, value, setOther)
            }
          />

          <ActionButtonsContainer>
            <Button
              loading={generateScriptMutation.isLoading}
              type="submit"
              style={style.gradientButton}
            >
              Next
            </Button>
          </ActionButtonsContainer>
        </Form>
      </div>
    );
  };

  const renderScriptAmends = () => {
    return (
      <div>
        <Message
          color="black"
          icon="edit"
          header="Your message"
          content="Below is the script we've generated, you can make any amendments by editing the below text."
        />

        <Form>
          <FormGroup>
            <ScriptBox script={script} setScript={setScript} />
          </FormGroup>

          <ActionButtonsContainer>
            <ButtonGroup>
              <Button
                onClick={() => setStepNumber((prev) => prev - 1)}
                style={style.gradientButton}
              >
                Back
              </Button>
              <Button
                onClick={() => setStepNumber((prev) => prev + 1)}
                style={style.gradientButton}
              >
                Next
              </Button>
            </ButtonGroup>
          </ActionButtonsContainer>
        </Form>
      </div>
    );
  };

  const renderAudioOptions = () => {
    return (
      <div>
        <Message
          color="black"
          icon="music"
          header="Voice & Music Selection"
          content="Finish up by selecting the voice to read the message, and the music you'd like to be played."
        />

        <Form>
          <FormGroup widths="equal">
            <FormSelect
              required
              fluid
              label="Voice"
              name="voice"
              options={voicesQuery.data.data.data.map((x) => ({
                text: x,
                value: x,
              }))}
              placeholder="Voice selection"
              value={voice}
              onChange={(_, { value }) => setVoice(value)}
            />

            <VoicePreview voice={voice} />

            <FormSelect
              required
              fluid
              label="Music"
              name="voice"
              options={[
                { text: "Flow", value: "flow" },
                { text: "Groovy", value: "groovy" },
              ]}
              placeholder="Music selection"
              value={music}
              onChange={(_, { value }) => setMusic(value)}
            />

            <MusicPreview music={music} />
          </FormGroup>

          <ActionButtonsContainer>
            <ButtonGroup>
              <Button
                disabled={generateFileMutation.isLoading}
                onClick={() => setStepNumber((prev) => prev - 1)}
                style={style.gradientButton}
              >
                Back
              </Button>

              <Button
                disabled={!voice || !music}
                loading={generateFileMutation.isLoading}
                onClick={() => generateFileMutation.mutate()}
                style={style.gradientButton}
              >
                Next
              </Button>
            </ButtonGroup>
          </ActionButtonsContainer>
        </Form>
      </div>
    );
  };

  const renderDownload = () => {
    return (
      <div>
        <Message
          color="black"
          icon="download"
          header="Download or Upload"
          content="Use the options below to listen, download or upload your music on hold."
        />

        <ButtonGroup>
          <GeneratedPreview url={generatedUrl} />

          <Button
            disabled={generateFileMutation.isLoading}
            onClick={() => window.open(generatedUrl)}
            style={style.purpleGradientButton}
            labelPosition="left"
            icon
          >
            <Icon name="download" />
            Download
          </Button>

          <Button
            disabled={generateFileMutation.isLoading}
            style={style.purpleGradientButton}
            labelPosition="left"
            icon
          >
            <Icon name="upload" />
            Send to iPECS
          </Button>

          <Button
            disabled={generateFileMutation.isLoading}
            style={style.purpleGradientButton}
            labelPosition="left"
            icon
          >
            <Icon name="upload" />
            Send to Horizon
          </Button>
        </ButtonGroup>

        <ActionButtonsContainer>
          <ButtonGroup>
            <Button
              onClick={() => setStepNumber((prev) => prev - 1)}
              style={style.gradientButton}
            >
              Back
            </Button>
          </ButtonGroup>
        </ActionButtonsContainer>
      </div>
    );
  };

  if (voicesQuery.isPending) return null;

  return (
    <Container
      style={{
        margin: "20px 0px",
        display: "flex",
        gap: "15px",
        flexDirection: "column",
      }}
    >
      <Header as="h2">Music on Hold</Header>

      <Segment>
        <Progress
          value={stepNumber + 1}
          total={4}
          attached="top"
          color="blue"
          active
        />
        Step {stepNumber + 1} of 4
        {(generateScriptMutation.isLoading ||
          generateFileMutation.isLoading) && (
          <Dimmer active>
            <Loader />
          </Dimmer>
        )}
        <Progress value={stepNumber + 1} total={4} attached="bottom" />
      </Segment>

      {renderStep(stepNumber)}
    </Container>
  );
}

function VoicePreview({ voice }) {
  const [playingVoice, setPlayingVoice] = useState(voice);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    setPlaying(false);
    setPlayingVoice(voice);
  }, [voice]);

  const previewRef = useRef();

  const handleClick = async () => {
    try {
      const res = await getVoicePreview(voice);
      previewRef.current.src = res.data.data;
      previewRef.current.currentTime = 0;
      playing ? previewRef.current.pause() : previewRef.current.play();
      setPlaying(!playing);
    } catch (e) {
      alert("Failed to preview");
    }
  };

  return (
    <>
      <Button
        disabled={!playingVoice}
        style={{ alignSelf: "flex-end" }}
        primary
        icon
        onClick={handleClick}
      >
        <Icon name={playing ? "pause" : "play"} />
      </Button>

      <audio ref={previewRef} onEnded={() => setPlaying(false)} />
    </>
  );
}

function MusicPreview({ music }) {
  const [playingMusic, setPlayingMusic] = useState(music);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    setPlaying(false);
    setPlayingMusic(music);
  }, [music]);

  const previewRef = useRef();

  const handleClick = () => {
    previewRef.current.currentTime = 0;
    playing ? previewRef.current.pause() : previewRef.current.play();
    setPlaying(!playing);
  };

  return (
    <>
      <Button
        disabled={!playingMusic}
        style={{ alignSelf: "flex-end" }}
        primary
        icon
        onClick={handleClick}
      >
        <Icon name={playing ? "pause" : "play"} />
      </Button>

      <audio ref={previewRef} src={`/moh/music/${playingMusic}.mp3`} />
    </>
  );
}

function ScriptBox({ script, setScript }) {
  const [currentScript, setCurrentScript] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [disabled, setDisabled] = useState(true);

  const take = 10;

  const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  useEffect(() => {
    if (currentIndex < script.length) {
      setDisabled(true);
      const delay = randomIntFromInterval(10, 50);
      const timeout = setTimeout(() => {
        setCurrentScript(
          (prev) => prev + script.slice(currentIndex, currentIndex + take),
        );
        setCurrentIndex((prevIndex) => prevIndex + take);
      }, delay);

      return () => clearTimeout(timeout);
    } else {
      setDisabled(false);
    }
  }, [currentIndex, script]);

  return (
    <TextareaAutosize
      maxLength={1800}
      readOnly={disabled}
      name="script"
      placeholder="Your script goes here"
      value={currentScript}
      onChange={(e) => {
        setScript(e.target.value);
        setCurrentScript(e.target.value);
      }}
    />
  );
}

function GeneratedPreview({ url }) {
  const [playingUrl, setPlayingUrl] = useState(url);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    setPlaying(false);
    setPlayingUrl(url);
  }, [url]);

  const previewRef = useRef();

  const handleClick = () => {
    previewRef.current.currentTime = 0;
    playing ? previewRef.current.pause() : previewRef.current.play();
    setPlaying(!playing);
  };

  return (
    <>
      <Button
        style={style.purpleGradientButton}
        disabled={!playingUrl}
        labelPosition="left"
        icon
        onClick={handleClick}
      >
        <Icon name={playing ? "pause" : "play"} />
        Preview
      </Button>

      <audio ref={previewRef} src={playingUrl} />
    </>
  );
}

function ActionButtonsContainer({ children }) {
  return (
    <div style={{ marginTop: "48px", marginBottom: "24px" }}>{children}</div>
  );
}

export default Moh;
