import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  ButtonGroup,
  Dropdown,
  Image,
  TableCell,
  TableRow,
} from "semantic-ui-react";
import {
  summariseCallRecording,
  transcribeCallRecording,
} from "../../api/transcriptions";
import sidekickLogoWhite from "../../images/sidekick-logo-white-75x79.png";
import { style } from "../../style";

const CallRecordingRow = ({
  id,
  number,
  start,
  end,
  extension,
  direction,
  duration,
  transcription,
  setInfoModal,
  searchParams,
}) => {
  const queryClient = useQueryClient();

  const [isProcessing, setIsProcessing] = useState(
    ["TRANSCRIBING", "SUMMARISING"].includes(transcription?.status?.value)
      ? true
      : false,
  );

  useEffect(() => {
    setIsProcessing(
      ["TRANSCRIBING", "SUMMARISING"].includes(transcription?.status?.value)
        ? true
        : false,
    );
  }, [transcription?.status?.value]);

  const handleError = (error) => {
    toast(
      error.response?.data?.message ?? "There was an error with your request",
      {
        type: "error",
        autoClose: 10000,
        toastId: "sidekick-limit-error",
      },
    );
  };

  return (
    <TableRow>
      <TableCell>{extension}</TableCell>
      <TableCell>{number}</TableCell>
      <TableCell>{direction}</TableCell>
      <TableCell>{start}</TableCell>
      <TableCell>{end}</TableCell>
      <TableCell>{duration}</TableCell>
      <TableCell textAlign="right">
        <ActionButton
          isProcessing={isProcessing}
          setIsProcessing={setIsProcessing}
          transcription={transcription}
          setInfoModal={setInfoModal}
          searchParams={searchParams}
          queryClient={queryClient}
          id={id}
          handleError={handleError}
        />
      </TableCell>
    </TableRow>
  );
};

function ActionButton({
  isProcessing,
  setIsProcessing,
  transcription,
  setInfoModal,
  searchParams,
  queryClient,
  id,
  handleError,
}) {
  const handleAiClick = () => handleRequest(summariseCallRecording);
  const handleTranscribeClick = () => handleRequest(transcribeCallRecording);

  const handleRequest = async (apiRequest) => {
    setIsProcessing(true);
    try {
      await apiRequest({ slug: id, ...searchParams });
      queryClient.invalidateQueries({
        queryKey: ["call-recordings"],
      });
      queryClient.invalidateQueries({
        queryKey: ["call-transcriptions"],
      });
    } catch (e) {
      setIsProcessing(false);
      handleError(e);
    }
  };

  if (transcription || isProcessing) {
    return (
      <Button
        fluid
        style={style.gradientButtonInactive}
        loading={isProcessing}
        disabled={isProcessing}
        onClick={() =>
          setInfoModal({
            open: true,
            id: transcription?.id,
            tab: transcription?.has_summary ? "summary" : "transcription",
          })
        }
      >
        View
      </Button>
    );
  }

  return (
    <ButtonGroup>
      <Button
        style={{
          ...style.purpleGradientButton,
          width: "118px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "8px 21px",
          justifyContent: "space-around",
        }}
        onClick={handleAiClick}
      >
        <Image
          src={sidekickLogoWhite}
          alt="Sidekick logo"
          style={{ width: "20px", float: "left" }}
        />
        <div>AI</div>
      </Button>

      <Dropdown
        style={style.purpleGradientButton}
        className="button icon"
        floating
        options={[
          {
            key: "transcribe",
            text: "Transcribe only",
            value: "transcribe",
            onClick: handleTranscribeClick,
          },
        ]}
        trigger={<></>}
      />
    </ButtonGroup>
  );
}

export default CallRecordingRow;
