export function getSentimentIcon(rating) {
  switch (rating) {
    case 1:
      return { name: "frown", color: "#e85858" };
    case 2:
      return { name: "frown", color: "#ca98d9" };
    case 3:
      return { name: "meh", color: "#98b6d9" };
    case 4:
      return { name: "smile", color: "#58b9b7" };
    case 5:
      return { name: "smile", color: "#5cb958" };
    default:
      return null;
  }
}
